import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getAllProposal, getSendFundContract, } from "../../utils/contractHelpers";
import { parseHTML } from "jquery";
const UserProposalSend = () => {
    const web3 = useWeb3();
    const contractAddress = environment.CreateProposal;
    const contract = getAllProposal(contractAddress, web3);
    const UserProposeContrct = useCallback(
        async (target, values, nftIds, signatures, calldatas, description, title, fundType, account, type, currencySend) => {
            // let ab={'0xA8C14159c435e973Fef0693358Ecdd6e4FfE89d1','220080000000'}
            console.log("in send proposal", target, values, nftIds, signatures, calldatas, description, title, fundType, account, type, currencySend)
            let final1 = []
            if (fundType === 'Tomi NFT') {
                if (type === 'ERC1155') {
                    console.log("1155")
                    let final = web3.eth.abi.encodeParameters(['address', 'address', 'uint256', 'uint256', 'bytes'], calldatas)
                    final1.push(final)
                } else {
                    console.log("721")
                    let final = web3.eth.abi.encodeParameters(['address', 'address', 'uint256'], calldatas)
                    final1.push(final)
                }
            } else {
                if (currencySend === 'Tomi Token') {
                    console.log("123")
                    let final = web3.eth.abi.encodeParameters(['address', 'uint256'], calldatas)
                    final1.push(final)
                } else if(currencySend === 'USDT') {
                    console.log("151")
                    let final = web3.eth.abi.encodeParameters(['address', 'uint256'], calldatas)
                    final1.push(final)
                }else if(currencySend === 'ETH'){
                    console.log("15")
                    final1.push('0x')
                }
            }
            console.log('detailed', nftIds, target, values, signatures, final1,title, description)
            //   console.log("sdfsfdsfdsf",targets, values, signatures,calldatas,address,contract1, description, title)
            // let convertedvalue =[web3.utils.toWei(values.toString(), "ether")]
            try {
                let gasPrice = await web3.eth.getGasPrice();
                const gas = await contract.methods
                .propose(nftIds, target, values, signatures, final1, title, description).estimateGas({from: account})
                const details = await contract.methods
                    .propose(nftIds, target, values, signatures, final1, title, description)
                    .send({
                        from: account,
                        gas,
                        gasPrice:gasPrice
                    })
                // console.log("resssssss",gas)
                return details;
            } catch (error) {
                console.log("catchhhh", error)
                throw (error)
            }


        },
        [contract]
    );
    return { UserProposeContrct: UserProposeContrct };
};
export default UserProposalSend;