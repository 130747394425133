import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { useWeb3React } from "@web3-react/core";
import { getAllProposal, } from "../../utils/contractHelpers";
import { parseHTML } from "jquery";
import Signature from "./userSign";
const UserProposalSimple = () => {
    const { account } = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = environment.CreateProposal;
    const contract = getAllProposal(contractAddress, web3);
    const UserProposeContrct = useCallback(
        async (target,values,description,title,nftIds) => {
            target=['0x0000000000000000000000000000000000000000']
            values=['0']
            let signature=['contentModeration']
            let calldatas=['0x']
            let dumArr=[]
            // dumArr.push(nftIds)
            // let final = web3.encode()
            console.log("in send proposal", nftIds,target,values,signature,calldatas,title,description, account)
            try {
                let gasPrice = await web3.eth.getGasPrice();
                const gas = await contract.methods
                    .propose(nftIds,target,values,signature,calldatas,title,description).estimateGas({ from: account })
                const details = await contract.methods
                    .propose(nftIds,target,values,signature,calldatas,title,description)
                    .send({
                        from: account,
                        // value: web3.utils.toWei('0'.toString(), "ether"),
                        gas,
                        gasPrice:gasPrice
                    })
                // console.log("resssssss",gas)
                return details;
            } catch (error) {
                console.log("catchhhh", error)
                throw (error)
            }

        },
        [contract]
    );
    return { UserProposeContrct: UserProposeContrct };
};
export default UserProposalSimple;