import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getAllProposal, } from "../../utils/contractHelpers";
import { parseHTML } from "jquery";
const UserProposalDesign = () => {
    const web3 = useWeb3();
    const contractAddress = environment.CreateProposal;
    const contract = getAllProposal(contractAddress, web3);
    const UserProposeContrct = useCallback(
        async (target, nftIds,values, signature,calldatas, description, title, account) => {
            let abs=[]
            let final = web3.eth.abi.encodeParameters(['address'], calldatas)
            let final2 = web3.eth.abi.encodeParameters(['bytes'], [final])
            abs.push(final2)
            console.log("in send proposal", target, nftIds,values, signature,abs, description, title, account)
            try {
                let gasPrice = await web3.eth.getGasPrice();
                const gas = await contract.methods.propose(nftIds,target,values,signature,abs,title,description).estimateGas({from: account})
                    const details = await contract.methods
                    .propose(nftIds, target, values, signature, abs, title, description)
                    .send({
                        from: account,
                        gas,
                        gasPrice:gasPrice
                    })
                // console.log("resssssss",gas)
                return details;
            } catch (error) {
                console.log("catchhhh", error)
                throw (error)
            }
        },
        [contract]
    );
    return { UserProposeContrct: UserProposeContrct };
};
export default UserProposalDesign;