import web3NoAccount from './web3'
import mintAbi from './mintAbi.json'
import tomiTokenDao from './tomiTokenDao.json'
import tomiToken from './TomiTokenAbi.json'
import treasury from './treasuryAmount.json'
import sendfund from './tomiFundsDao.json'
import mainDao from './tomiMainDaoAbi.json'
import pioneerNftDao from './pioneerNftDao.json'
import auctionDao from './tomiAuctionAbi.json'
import designDao from './tomiDesignAbi.json'
import devFunds from './tomiDevFundAbi.json'
import proposeAll from './proposeAll.json'
import priceOracle from './priceOracle.json'
import volumeRequest from './volumeRequest.json'

const getContract = (abi, address, web3) => {
    const _web3 = web3 ?? web3NoAccount;
    // console.log('_web3',_web3);s
    return new _web3.eth.Contract(abi, address)
}

export const getTomiTokenProposalContract = (address, web3) => {
    return getContract(tomiTokenDao, address, web3)
}

export const getMintContract = (address, web3) => {
    return getContract(mintAbi, address, web3)
}

export const getTomiContract = (address, web3) => {
    return getContract(tomiToken, address, web3)
}
export const getTreasuryContract = (address, web3) => {
    return getContract(treasury, address, web3)
}

export const getSendFundContract = (address, web3) => {
    return getContract(sendfund, address, web3)
}

export const getMainDaoContract = (address, web3) => {
    return getContract(mainDao, address, web3)
}

export const getPioneerDaoContract = (address, web3) => {
    return getContract(pioneerNftDao, address, web3)
}

export const getVolumePrice = (address, web3) => {
    return getContract(volumeRequest, address, web3)
}

export const getAuctionContract = (address, web3) => {
    return getContract(auctionDao, address, web3)
}

export const getDesignDaoContract = (address, web3) => {
    return getContract(designDao, address, web3)
}

export const getAllProposal = (address, web3) => {
    return getContract(proposeAll, address, web3)
}

export const getTomiPrices = (address, web3) => {
    return getContract(priceOracle, address, web3)
}

export const getDevFundContract = (address, web3) => {
    return getContract(devFunds, address, web3)
}