import React, { useEffect, useState } from 'react'
import styled from "@emotion/styled/macro";
import "./editprofile.scss"
import { useWeb3React } from "@web3-react/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch } from 'react-redux'
import Loader from "../../hooks/loader"
import { GetUsers } from '../../redux/action'
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../utils/ApiUrl";
import Signature from "../../hooks/dataSenders/userSign";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

const Copied = styled("span")`
  font-size: 16px;
  margin-left: 10px;
  position: absolute;
            right: 14px;
            top: 12px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #FF0083;
            text-decoration: none;
            border: none;
            background-color: transparent;
`;
const HOVER_TIMEOUT = 1000;



const Editprofile = () => {
    const dispatch = useDispatch();
    const { userSign } = Signature();
    const token = JSON.parse(localStorage?.getItem('mytoken'))
    const history = useHistory()
    const users = useSelector((state) => state.UserReducer.GetUserAll);
    console.log("eserr", users)
    const [selectedImg1, setSelectedImg1] = useState();
    const [mainLoader, setMainLoader] = useState(false);
    const [myFiles, setMyFiles] = useState();
    const { account } = useWeb3React();
    const [copy, setCopy] = useState(false);
    const [inputs, setInputs] = useState({
        userEmail: "",
        name: "",
        discordLink: "",
        walletAddress: "",
    })

    console.log("inputsssss", inputs)

    const userLogin = async () => {
        const res1 = await userSign();
        if (res1 && account) {
            console.log("herererer")
            axios.post(API_URL + "users/login", { object: { address: account, name: "Tomi Dao" }, sign: res1 })
                .then((response) => {
                    console.log("resss", response.data.token)
                    const token = response.data.token
                    localStorage.setItem('mytoken', JSON.stringify({ token, account }))
                    dispatch(GetUsers(account, token));

                }).catch((err) => {
                    //   setOpen(false)
                    //   toast.error(err.response?.data.msg, {
                    //     position: "top-center",
                    //     autoClose: 2000,
                    //   });
                })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const editUser = async (event) => {

        setMainLoader(true)
        event.preventDefault();
        const data = new FormData();
        if (inputs.name) {
            data.append("name", inputs?.name);
        } else {
            data.append("name", users?.name);
        }
        if (inputs.userEmail) {
            data.append("userEmail", inputs.userEmail);
        } else {
            data.append("userEmail", users?.userEmail);
        }
        if (inputs.discordLink) {
            data.append("discordLink", inputs.discordLink);
        } else {
            data.append("discordLink", users?.discordLink);
        }

        // data.append("userEmail", inputs.userEmail ? inputs.userEmail : users?.userEmail );
        // data.append("discordLink", inputs.discordLink ? inputs.discordLink : users?.discordLink);
        if (myFiles) {
            data.append("picture", myFiles);
        }
        data.append(
            "walletAddress", account
        );
        //   data.append("name", "any");
        data.append("role", "user");

        console.log("yeeeeeee")
        axios
            .post(API_URL + "users/editUser", data, { headers: { "Authorization": `Bearer ${token?.token}` } })
            .then((response) => {
                setMainLoader(false);
                toast.success('Profile Edited', {
                    position: "top-center",
                    autoClose: 2000,
                });

                history.push('/home')


                dispatch(GetUsers(account, token?.token));
                //   history.push("/profile");
                // setInputs({
                //     name: '',
                //     description: '',
                //     routeLink: '',
                //     routePage: '',
                //     category: '',
                //     type: '',
                //     image: ''
                // })
                // setSelectedImg('');
            })
            .catch((err) => {
                if (err.toString().slice(39, 43) === '401') {
                    userLogin()
                }

                setMainLoader(false);
                toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });

            });
        // } else {
        //   setMainLoader(false);
        //   toast.error("name cannot be empty", {
        //     position: "top-center",
        //     autoClose: 2000,
        //   });
        // }
    }

    const deleteProfile = () => {
        setMainLoader(true)
        axios.post(API_URL + `users/deleteCoverPhoto`, {}, { headers: { "Authorization": `Bearer ${token?.token}` } })
            .then((response) => {
                setMainLoader(false);
                toast.success('Profile Deleted', {
                    position: "top-center",
                    autoClose: 2000,
                });
                dispatch(GetUsers(account, token?.token));
                //   history.push("/profile");
            }).catch((error) => {
                setMainLoader(false)
            })
        setMyFiles();
        setSelectedImg1(null);
    };


    const handleFileSelect = (evt) => {
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));
            setSelectedImg1(filesarray[0]);
        }
        var files = evt.target.files;
        var file = files[0];
        setMyFiles(file)
    }
    return (
        <>
            {mainLoader && <Loader />}
            <section className='Editprofile'>
                <img src='\assets\linesshade.svg' className='linesshades img-fluid'></img>
                <img src='\assets\Ellipse.svg' className='linesshades2 img-fluid'></img>
                <div className='container-fluid p-0'>
                    <div className='uppper_part'>
                        <div className='mainheadings'>
                            <h4 className='head'>Edit profile</h4>
                            <p className='para'>You can set preferred display name and manage other personal settings All Fields Are Optional</p>
                        </div>
                        <div className=' myyydirection'>

                            <div className='rightmain'>

                                <div className='maininner'>
                                    <img src={selectedImg1 ? selectedImg1 : users.picture ? users.picture : '\avatar-03.svg'} className='avatarimg' alt='img' />
                                    <div className='innered'>
                                        <h6>Upload profile image</h6>
                                        <p>We recommend to upload images in 400x400 resolution. Max 5 MB in JPEG or PNG format</p>
                                        <div className='inning'>
                                            <label className='upload-btn' htmlFor='upload' >Upload</label>
                                            <input type="file" className='d-none' onChange={handleFileSelect} accept="image/*" id='upload' />
                                            <div className='' onClick={deleteProfile}>
                                                <img src='\assets\trassh.svg' alt='img' className='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='uppper_inputs'>
                                <div className='firstttt'>
                                    <div className='maininput'>
                                        <div>
                                            <input type="text" value={account ? account : '0x23BcE9222ef542670e47AE15D8da841C03495cFD'} name='walletAddress' className='innerinput' />
                                            {/* <button className='copy'>Copy</button> */}
                                            {copy ? (
                                                <Copied>Copied</Copied>
                                            ) : (
                                                // {account}
                                                <CopyToClipboard
                                                    text={account}
                                                    onCopy={() => {
                                                        setCopy(true);
                                                        setTimeout(
                                                            () => setCopy(false),
                                                            HOVER_TIMEOUT
                                                        );
                                                    }}
                                                >
                                                    <button className='copy'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M13.3332 10.75V14.25C13.3332 17.1667 12.1665 18.3333 9.24984 18.3333H5.74984C2.83317 18.3333 1.6665 17.1667 1.6665 14.25V10.75C1.6665 7.83334 2.83317 6.66667 5.74984 6.66667H9.24984C12.1665 6.66667 13.3332 7.83334 13.3332 10.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.3332 5.75001V9.25001C18.3332 12.1667 17.1665 13.3333 14.2498 13.3333H13.3332V10.75C13.3332 7.83334 12.1665 6.66667 9.24984 6.66667H6.6665V5.75001C6.6665 2.83334 7.83317 1.66667 10.7498 1.66667H14.2498C17.1665 1.66667 18.3332 2.83334 18.3332 5.75001Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></button>
                                                </CopyToClipboard>
                                            )}
                                            {/* <buttton href='' className='copy'/>Copy */}
                                        </div>

                                    </div>
                                    <div className='maininput'>

                                        <input type="text" name='name' value={inputs?.name ? inputs?.name : users.name} onChange={handleChange1} placeholder='Enter your display name' className='innerinput' />
                                    </div>
                                </div>
                                <div className='firstttt'>
                                    <div className='maininput'>

                                        <input type="text" name='userEmail' value={inputs?.userEmail ? inputs?.userEmail : users.userEmail} onChange={handleChange1} placeholder='Enter your email' className='innerinput' />
                                    </div>
                                    <div className='maininput'>

                                        <input type="text" name='discordLink' value={inputs?.discordLink ? inputs?.discordLink : users.discordLink} onChange={handleChange1} placeholder='Enter your Discord' className='innerinput' />
                                    </div>
                                </div>
                            </div>


                            <button className='endbtn' onClick={editUser}>Submit</button>
                        </div>
                    </div>
                </div>








            </section>

        </>
    )
}

export default Editprofile