import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import { getTomiTokenProposalContract, getSendFundContract, getPioneerDaoContract ,getDesignDaoContract, getAuctionContract, getMainDaoContract ,getDevFundContract} from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";
const UserExecuteData = () => {
    const web3 = useWeb3();
    // const contractAddress = environment.TomiTokenDao;

    // const contract = getTomiTokenProposalContract(contractAddress, web3);
    const execute1 = useCallback(
        async (id,address,fid) => {
            // console.log("in hook data",id,address,fid);
            let contract = ''
            if (address === Environment.TomiTokenDao.toLowerCase()) {
                console.log("here");
                contract = getTomiTokenProposalContract(address, web3);
            } else if(address === Environment.TomiFundsDao.toLowerCase()) {
                console.log("here1");
                contract = getSendFundContract(address, web3);
            } else if(address === Environment.PioneerNftDao.toLowerCase()){
                contract = getPioneerDaoContract(address, web3);
            } else if(address === Environment.TomiDesignDao.toLowerCase()){
                contract = getDesignDaoContract(address, web3);
            } else if(address === Environment.TomiAuctionDao.toLowerCase()){ 
                contract = getAuctionContract(address, web3);
            } else if(address === Environment.TomiMainDao.toLowerCase()){
                contract = getMainDaoContract(address, web3);
            } else if(address === Environment.TomiDevFunds.toLowerCase()) {
                console.log("here2",address);
                contract = getDevFundContract(address, web3);
            }
            
            let Arr = ['', 'getProposalsChangeEmissionData', 'getProposalsWalletData', 'getProposalsWalletData', 'executeUpdateWallet',]
            Arr[16] = 'getProposalsSendTokenData'
            Arr[17] = 'getProposalsSendTokenData'
            Arr[18] = 'getProposalsSendTokenData'
            Arr[19] = 'getProposalsSendNFTData'
            Arr[5] = 'getProposalsSendNFTData'
            Arr[12]= 'getProposalsDaoData'
            let a = Arr[fid]

            
            // a.push(NFtID)

            console.log("sdfsfdsfdsf", a, id,fid, address,contract)
            try {
                // const gas = await contract.methods
                //     .castVote(id, support,a).estimateGas({from: account})
                const details = await contract.methods[`${a}`](id).call()
                return details;
            } catch (error) {
                console.log("catchhhh", error)
                throw (error)
            }
        },
        [web3]
    );
    return { execute1: execute1 };
};
export default UserExecuteData;

