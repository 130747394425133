import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import './buttonComponent.scss'
import useWeb3 from '../../hooks/useWeb3';
import { useWeb3React } from "@web3-react/core";
import { UserData, UserVoteStatus, useNftUsed, ProposalStatus, UserNftVoted, GetConsensus, ProposalPostState, useMinVOTEReq, useBaseFeeGet } from '../../hooks/useBalances'
import UserVote from '../../hooks/dataSenders/vote';
import UserExecute from '../../hooks/dataSenders/executes';
import UserExecuteData from '../../hooks/dataSenders/userRequireData';
import Loader from "../../hooks/loader"
import { toast } from "react-toastify";
import { API_URL } from '../../utils/ApiUrl';
import Signature from "../../hooks/dataSenders/userSign";
import { useDispatch } from 'react-redux'
import { GetUsers } from "../../redux/action";
import Environment from '../../utils/Environment';
import { logDOM } from '@testing-library/react';
import { ConsoleView } from 'react-device-detect';

function Executed() {
    const { id, address } = useParams();
    const web3 = useWeb3();
    const { account } = useWeb3React();
    const [id1, setId1] = useState(null);
    useEffect(() => {
        if (id) {
            setId1(id);
        }
    }, [id])
    const [proposal, setProposals] = useState()
    const history = useHistory()
    const token = JSON.parse(localStorage.getItem('mytoken'))
    const { CheckNftUsed } = useNftUsed()
    const { CheckNftVoted } = UserNftVoted()
    const [userPropseTRx, setuserPropseTRx] = useState()
    const dispatch = useDispatch();
    const { userSign } = Signature();
    const [inputs, setInputs] = useState('')
    const [vote, setVote] = useState()
    const [userAllNft, setUserAllNft] = useState([]);
    const [userProposedTrx, setProposedTrx] = useState();
    const [mainLoader, setMainLoader] = useState(false);
    console.log("loader", mainLoader)
    const proposalBaseFee = useBaseFeeGet();
    const minReqNFtVote = useMinVOTEReq()
    const [mainDisable, setMainDisable] = useState(false);
    const [allCommentss, setAllCommentss] = useState([]);
    const [ABiFun, setABiFun] = useState()
    const userInfo = UserData(id)
    // console.log("datasss", userInfo);
    // const userInfo = {}

    const voteStatus = UserVoteStatus(id, userAllNft)
    console.log("id::::::::::::::::::::", typeof userAllNft.length, typeof minReqNFtVote,userAllNft)

    const proposalPostState = ProposalPostState(id, address)
    // const voteStatus = false;
    console.log('this component', address, userInfo, ABiFun)
    const totalConsensus = GetConsensus(id, address)


    const ProposalUserStatus = ProposalStatus(id)
    // console.log("df:PropseTRx)
    const { voted } = UserVote()
    const { execute } = UserExecute()
    const { execute1 } = UserExecuteData()
    const a = Date.now() / 1000


    const getProposals = async () => {
        // setMainLoader(true);
        var data = JSON.stringify({
            query: ` {
                proposalCreateds(
                    where: {proposalId: "${id}"}
                  ) {
                     proposer
                     id
                     proposalId
                     proposer
                     targets
                     values
                     signatures
                     calldatas
                     startTime
                     endTime
                     title
                     description
                     quorumVotes
                     consensusVotes
                     forVotes
                     againstVotes
                     abstainVotes
                     state
                                transaction {
                                    id
                                  }
                  }
            }`,
            variables: {},
        });
        var config = {
            method: "post",
            url: "https://api.studio.thegraph.com/query/41280/tomidao/v0.0.3",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };
        await axios(config)
            .then(async function (response) {
                setProposals(response.data.data.proposalCreateds[0])
                // setMainLoader(false);
                //   console.log("ressssss",response.data.data)
            })
            .catch(function (error) {
                console.log(error);
                // setMainLoader(false);
            });
    };

    const userNFt = () => {
        setMainLoader(true)
        const options = {
            method: 'GET',
            url: `https://deep-index.moralis.io/api/v2/${account}/nft?chain=eth&format=decimal&normalizeMetadata=false&token_addresses=${Environment.MintContract}`,
            headers: { accept: 'application/json', 'X-API-Key': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6Ijk5MTFmMDdiLWZkNGMtNDBmZS05MjQ0LWU5ZmNlOWVlNjY2MiIsIm9yZ0lkIjoiMjA5OTMxIiwidXNlcklkIjoiMjA5NjAzIiwidHlwZUlkIjoiNjgzMjA5NjgtZTEzNy00MTA1LWFjNDYtM2ZlNGRmNDViMGZlIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTY0OTAzNDEsImV4cCI6NDg3MjI1MDM0MX0.GR-teydWjr1g7RLcBh_x1QC-ECgy7Z6e-nTmGiE2qJ8' }
        };

        const a = axios
            .request(options)
            .then(async function (response) {
                console.log(response.data.total);
                let dummArr = []
                // for (let i = 0; i < response?.data?.total; i++) {
                //     //   console.log("innnnnnnnnnn loooop", response?.data?.result[i].token_id);
                //     let ab = await CheckNftVoted(response?.data?.result[i]?.token_id, id)
                //     console.log("ABBBBB", ab)
                //     if (ab === false) {
                //         dummArr.push(response?.data?.result[i]?.token_id)
                //     }
                // }
                let promise = await Promise.all(
                    response?.data?.result.map((data) => {
                        return CheckNftVoted(data?.token_id, id)
                        // console.log("ABBBBB", ab)
                        // if (ab === false) {
                        // return  dummArr.push(data?.token_id)
                        // }
                    })
                )
                for (let i = 0; i < promise?.length; i++) {
                    console.log("ABBBBB111loooop", promise[i]);
                    if (promise[i] === false) {
                        dummArr.push(response?.data?.result[i]?.token_id)
                    }
                }
                console.log("ABBBBB111", dummArr)
                setUserAllNft(dummArr)
                setMainLoader(false)
                return response.data.result
            })
        return a
            .catch(function (error) {
                console.error(error);
                setMainLoader(false)
            });
    }


    const ExecuteProposal = async () => {

        // debugger;
        setMainLoader(true)
        setId1(null)
        // console.log('this component:', a)
        // if (userInfo?.functionId === '1') {
        //     a='updateEmissions'
        //   } else if (userInfo?.functionId === '2') {
        //       a='updateMarketingWallet'
        //   } else if (userInfo?.functionId === '3') {
        //     a='updateTomiWallet'
        //   } else if (userInfo?.functionId === '4') {
        //     a='changeBlockState'
        //   }
        try {
            // setMainLoader(true)
            const res = await execute(
                id.toString(),
                account,
            );

            if (res) {

                // console.log("proposal res", res)
                setId1(id);
                getProposals()
                window.location.reload()
                setMainLoader(false)
                // setMainLoader(false);
                await toast.success("Proposal Executed Successfully");
            }
            // history.push('/home')
        } catch (error) {
            setMainLoader(false)
            toast.error(error.message)
            console.log("eerrr", error)
            // await setError(error.message)
            // setMainLoader(false);
        }

    }

    const VoteFor = async () => {
        // window.$("#exampleModalvote").modal("hide");
        setMainLoader(true)
        setId1(null);
        // const res1 = await userNFt();
        // await console.log("option", res1);
        // setUserBalance(res1)
        try {
            const res = await voted(
                id.toString(),
                vote.toString(),
                account,
                userAllNft
            );
            getProposals()
            setId1(id);
            window.location.reload()
            // console.log("proposal res", res)
            setMainLoader(false)
            window.$("#exampleModalvote").modal("hide");


            // setMainLoader(false);
            await toast.success("Vote Submitted Successfully");
        } catch (error) {
            setMainLoader(false)
            window.$("#exampleModalvote").modal("hide");
            window.location.reload()
            toast.error(error.message)
            console.log("eerrr", error)
            // await setError(error.message)
            // setMainLoader(false);
        }

    }

    const userRegister = async () => {
        console.log("hererererssssssssssss")
        // const res1 = await userSign();
        if (account) {
            axios.post(API_URL + "users/register", { walletAddress: account })
                .then((response) => {
                    console.log("resss", response)
                    userLogin()
                    // setLoginnRes(response.data)
                    // const token = response.data.token
                    // localStorage.setItem('mytoken', token)
                    // setOpen(false)
                    // history.push('admin/dashboard');
                    // window.$('#qrcode').modal('show')

                }).catch((err) => {
                    userLogin()
                    if (err.toString().slice(39, 43) === '401') {
                        userLogin()
                    }
                    // console.log("response in login", err.response?.data.doesSecretKeyFound)
                    // window.$('#onlyinput').modal('show')
                    // setOpen(false)
                    // toast.error(err.response?.data.msg, {
                    //     position: "top-center",
                    //     autoClose: 2000,
                    // });
                })
        }
    }

    const userLogin = async () => {
        const res1 = await userSign();
        if (res1 && account) {
            console.log("herererer")
            axios.post(API_URL + "users/login", { object: { address: account, name: "Tomi Dao" }, sign: res1 })
                .then((response) => {
                    console.log("resss", response.data.token)
                    const token = response.data.token
                    localStorage.setItem('mytoken', JSON.stringify({ token, account }))
                    dispatch(GetUsers(account, token));

                }).catch((err) => {
                    //   setOpen(false)
                    //   toast.error(err.response?.data.msg, {
                    //     position: "top-center",
                    //     autoClose: 2000,
                    //   });
                })
        }
    }

    const gata = async () => {
        // let res1111 = await CheckUserNft();
        // let res2 = await UserWalleet(account);
        if (userInfo) {
            console.log("gata", userInfo?.signatures);
            for (let i = 0; i < userInfo?.signatures.length; i++) {
                if (userInfo?.signatures[i].startsWith('updateEmissions')) {


                    let s = userInfo?.signatures[i].split('((')[1]
                    s = s?.replace("))", "")
                    s = s?.split(",")

                    let final = web3.eth.abi.decodeParameters(s, userInfo?.calldatas[i])
                    setuserPropseTRx(final)
                    console.log('sssss', final)
                    for (let i = 0; i < userInfo?.signatures.length; i++) {
                        let s = userInfo?.signatures[i].split('((')[0]
                        setABiFun(s)
                        // s = s.replace(")" , "")
                        // s = s.split(",")
                        // let final = web3.eth.abi.decodeParameters(s,userInfo.calldatas[i])
                        // setuserPropseTRx(final)
                        // console.log('sssss' , s)

                    }
                } else if (userInfo?.signatures[i].startsWith('fundDao')) {
                    let s = userInfo?.signatures[i].split('(')[0]
                    setABiFun(s)
                } else {

                    let s = userInfo?.signatures[i].split('(')[1]
                    s = s?.replace(")", "")
                    s = s?.split(",")
                    console.log("sssssssss", s)
                    let final = web3?.eth?.abi?.decodeParameters(s, userInfo?.calldatas[i])
                    setuserPropseTRx(final)
                    console.log('sssss', s)

                    for (let i = 0; i < userInfo?.signatures.length; i++) {
                        let s = userInfo?.signatures[i].split('(')[0]
                        setABiFun(s)
                        // s = s.replace(")" , "")
                        // s = s.split(",")
                        // let final = web3.eth.abi.decodeParameters(s,userInfo.calldatas[i])
                        // setuserPropseTRx(final)
                        // console.log('sssss' , s)

                    }

                }

                // let res1 = await execute1(id1, address, userInfo?.functionId);
                // setProposedTrx(res1)
                // console.log("res gata", res1[0])
            }
        }

    };

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const addComment = () => {
        // setMainLoader(true)
        //     setOpen(true)
        if (inputs.comment) {
            setMainDisable(true)
            axios.post(API_URL + "comment/addComment", { comment: inputs.comment, proposalId: parseInt(id) }, { headers: { "Authorization": `Bearer ${token?.token}` } })
                .then((response) => {
                    console.log("resssssss", response)
                    if (response) {
                        setInputs({ comment: '' })
                        getAllComents()
                        setMainDisable(false)
                    }
                    // setMainLoader(false)
                    // const token = response.data.token
                    // localStorage.setItem('mytoken', token)
                    // window.$('#qrcode').modal('show')

                }).catch((err) => {
                    setMainDisable(false)
                    // console.log("response in login", err.response?.data.doesSecretKeyFound)
                    // window.$('#onlyinput').modal('show')
                    // setOpen(false)
                    toast.error(err.response?.data.msg, {
                        position: "top-center",
                        autoClose: 2000,
                    });
                })
        } else {
            toast.error('Comments cannot be Empty', {
                position: "top-center",
                autoClose: 2000,
            });
        }

    }

    const getAllComents = () => {
        // setOpen(true)
        axios.post(API_URL + "comment/getAllCommentByProposalId", { proposalId: id }, { headers: { "Authorization": `Bearer ${token?.token}` } })
            .then((response) => {
                setAllCommentss(response.data.data)
                // setMyFiles(response.data.coins)
                // setOpen(false)

            }).catch((err) => {
                // setOpen(false)
                if (err.toString().slice(39, 43) === '401') {
                    userLogin()
                }
                toast.error(err.response?.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                });
            })
    }

    console.log("data we have here", userPropseTRx, ABiFun, userInfo?.signatures, userInfo[6]);

    // useEffect(() => {
    //     // fetchData();
    //     // getAllComents()
    //     // if(!token || token=== null){
    //     //     userRegister()
    //     // }
    // }, [token, account])
    useEffect(() => {
        // fetchData();
        if (userInfo) {
            gata()
        }

    }, [id, account, userInfo])

    useEffect(() => {
        // fetchData();
        if (id) {
            getAllComents()
            getProposals()
            userNFt()
            // gata()
        }

    }, [id, account, address])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {mainLoader && <Loader />}
            <section className='banner-main banner-main ptb'>
                <img src='\assets\linesshade.svg' className='linesshades img-fluid'></img>
                <img src='\assets\Ellipse.svg' className='linesshades3 img-fluid'></img>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-xl-11 col-lg-11 m-auto ">
                            {/* <div className="d-flex topTicket">
                                <p>Treasury</p>
                                <p>28,307</p>
                            </div> */}
                            <div className="pioneerDao">
                                <div className="governancebox topBtnsDiv">
                                    <div className='mainnnny'>

                                        <div className='textbtn'>
                                            <h5>Proposal {id}</h5>
                                            <button className={(ProposalUserStatus === 'Succeeded') || (ProposalUserStatus === 'Executed' && proposalPostState === 'Succeeded') ? "greyButtons border-0 bg-primary " : (ProposalUserStatus === 'Pending' || ProposalUserStatus === 'Active') ? "greyButtons border-0 bg-secondary" : (ProposalUserStatus === 'Vetoed' || ProposalUserStatus === 'Canceled' || ProposalUserStatus === 'Defeated' || proposalPostState === 'Defeated') ? "greyButtons border-0 bg-danger " : ""}>{ProposalUserStatus === 'Executed' && proposalPostState === 'Defeated' ? 'Defeated' : ProposalUserStatus}</button>
                                        </div>
                                        {/* <div>
                                            <button className='newwwwwbutton'> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path d="M12 2.5C6.49 2.5 2 6.99 2 12.5C2 18.01 6.49 22.5 12 22.5C17.51 22.5 22 18.01 22 12.5C22 6.99 17.51 2.5 12 2.5ZM16.35 16.07C16.21 16.31 15.96 16.44 15.7 16.44C15.57 16.44 15.44 16.41 15.32 16.33L12.22 14.48C11.45 14.02 10.88 13.01 10.88 12.12V8.02C10.88 7.61 11.22 7.27 11.63 7.27C12.04 7.27 12.38 7.61 12.38 8.02V12.12C12.38 12.48 12.68 13.01 12.99 13.19L16.09 15.04C16.45 15.25 16.57 15.71 16.35 16.07Z" fill="white" />
                                            </svg>Starts in 14 hours</button>
                                        </div> */}

                                    </div>

                                    <div className="d-flex  align-items-centers flex-wrap">
                                        <h3>{proposal?.title}</h3>
                                        {/* {a < proposal?.endTime ? */}

                                        {/* : <button className='exii'>Executed</button>} */}
                                    </div>
                                    <div className='randomtext'>
                                        <div className='texted'>
                                            {/* <p>Voting ended {(new Date(proposal?.endTime * 1000)).toLocaleDateString()}.</p> */}
                                            <p>This proposal has a reached Consensus of {`${((userInfo?.forVotes / userInfo?.consensusVotes) * 100) >= 100 ? '100' : ((userInfo?.forVotes / userInfo?.consensusVotes) * 100) < 100 ? (((userInfo?.forVotes / userInfo?.consensusVotes) * 100).toFixed(2)) : '0'} %`}</p>
                                            <p>Proposed by <span className='ashdgaschgxc'> {proposal?.proposer.slice(0, 5) + "........." + proposal?.proposer.substr(proposal?.proposer.length - 5)}</span>   at <span><a href={`https://etherscan.io/tx/${proposal?.transaction?.id}`} target='_blank'>{proposal?.transaction?.id.slice(0, 5) + "........." + proposal?.transaction?.id.substr(proposal?.transaction?.id.length - 5)}</a></span> </p>
                                        </div>
                                        <div className="votesDiv">

                                            <>
                                                {/* {voteStatus?.hasVoted === true && ProposalUserStatus === 'Active' ? <p className=''>You Have Already Voted</p>
        : ''
    } */}
                                            </>
                                            <>
                                                {a > proposal?.startTime && a < proposal?.endTime && voteStatus === false ?
                                                    userAllNft?.length >= parseInt(minReqNFtVote) ?
                                                        < button className='bg-pink mb-0' data-toggle="modal" data-target="#exampleModalvote">Submit Vote</button>
                                                        : <p>You required Min {minReqNFtVote} Pioneers to Vote </p>
                                                    : ProposalUserStatus === 'Succeeded' || ProposalUserStatus === 'Defeated' ?
                                                        < button className='bg-pink mb-0' onClick={ExecuteProposal}>Execute Proposal</button>
                                                        : a < proposal?.startTime ? "Voting Not Started Yet"
                                                            : voteStatus === true && ProposalUserStatus === 'Active' ? <p className=''>You Have Already Voted </p>
                                                                : (parseInt(userInfo.forVotes) + parseInt(userInfo.againstVotes) + parseInt(userInfo.againstVotes)) > parseInt(userInfo.quorumVotes) && ((parseInt(userInfo?.forVotes) / parseInt(userInfo?.consensusVotes)) * 100) >= '100' ?
                                                                    <p>This propsal has reached a {((userInfo?.forVotes / userInfo?.consensusVotes) * 100) >= 100 ? '100' : ((parseInt(userInfo?.forVotes) / parseInt(userInfo?.consensusVotes)) * 100) < 100 ? (((parseInt(userInfo?.forVotes) / parseInt(userInfo?.consensusVotes)) * 100).toFixed(2)) : '0'}% of Consensus and {(parseInt(userInfo.forVotes) + parseInt(userInfo.againstVotes) + parseInt(userInfo.againstVotes)) >= parseInt(userInfo.quorumVotes) ? '100' : (((parseInt(userInfo.forVotes) + parseInt(userInfo.abstainVotes) + parseInt(userInfo.againstVotes)) / parseInt(userInfo.quorumVotes)) * 100).toFixed(2)}% of Quorum  so its {proposalPostState}</p>
                                                                    : (parseInt(userInfo.forVotes) + parseInt(userInfo.againstVotes) + parseInt(userInfo.againstVotes) < parseInt(userInfo.quorumVotes)) && ((parseInt(userInfo?.forVotes) / parseInt(userInfo?.consensusVotes)) * 100) >= '100' ?
                                                                        <p>This propsal has reached a {((userInfo?.forVotes / userInfo?.consensusVotes) * 100) >= 100 ? '100' : ((userInfo?.forVotes / userInfo?.consensusVotes) * 100) < 100 ? (((parseInt(userInfo?.forVotes) / parseInt(userInfo?.consensusVotes)) * 100).toFixed(2)) : '0'}% of Consensus and {(parseInt(userInfo.forVotes) + parseInt(userInfo.againstVotes) + parseInt(userInfo.againstVotes)) >= parseInt(userInfo.quorumVotes) ? '100' : (((parseInt(userInfo.forVotes) + parseInt(userInfo.abstainVotes) + parseInt(userInfo.againstVotes)) / parseInt(userInfo.quorumVotes)) * 100).toFixed(2)}% of Quorum  so its {proposalPostState}</p>
                                                                        : (parseInt(userInfo.forVotes) + parseInt(userInfo.againstVotes) + parseInt(userInfo.againstVotes) < parseInt(userInfo.quorumVotes)) && ((parseInt(userInfo?.forVotes) / parseInt(userInfo?.consensusVotes)) * 100) <= '100' ?
                                                                            <p>This propsal has reached a {((userInfo?.forVotes / userInfo?.consensusVotes) * 100) >= 100 ? '100' : ((userInfo?.forVotes / userInfo?.consensusVotes) * 100) < 100 ? (((userInfo?.forVotes / userInfo?.consensusVotes) * 100).toFixed(2)) : '0'}% of Consensus and {(parseInt(userInfo.forVotes) + parseInt(userInfo.againstVotes) + parseInt(userInfo.againstVotes)) >= parseInt(userInfo.quorumVotes) ? '100' : (((parseInt(userInfo.forVotes) + parseInt(userInfo.abstainVotes) + parseInt(userInfo.againstVotes)) / parseInt(userInfo.quorumVotes)) * 100).toFixed(2)}% of Quorum  so its {proposalPostState}</p>
                                                                            : ''
                                                }
                                                {/* < button className='bg-pink mb-0' onClick={ExecuteProposal}>Execute Proposal</button> */}
                                                {/* < button className='bg-pink mb-0' data-toggle="modal" data-target="#exampleModalvote">Submit Vote</button> */}
                                            </>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="countSection">


                                <div className="row">
                                    <div className="col-md-4 pl-md-0">
                                        <div className="forAganist">
                                            <p>For</p>
                                            <h3>{userInfo?.forVotes}</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="forAganist">
                                            <p className='text-dangerr'>Against</p>
                                            <h3>{userInfo?.againstVotes}</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4 pr-md-0">
                                        <div className="forAganist">
                                            <p className='text-secondary'>Abstain</p>
                                            <h3>{userInfo?.abstainVotes}</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4 pl-md-0">
                                        <div className="ThresholdDiv">
                                            <div>
                                                <p>Threshold Consensus</p>
                                                {/* <p className='qutoa'>{userInfo?.consensusVotes} Votes</p> */}
                                            </div>
                                            <div >
                                                <p>Quorum</p>
                                                <p className='qutoa'>{userInfo?.quorumVotes} Votes</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ">
                                        <div className="ThresholdDiv">
                                            <p>{a < proposal?.endTime ? 'Voting Closes On' : 'Ended'}</p>
                                            <div >
                                                <p className=''>{(new Date(proposal?.endTime * 1000)).toGMTString().slice(0, 16)}</p>
                                                <p className='qutoa'>    {(new Date(proposal?.endTime * 1000)).toGMTString().slice(17, 30)}</p>
                                                {/* <p className='qutoa'>July 10, 2022</p> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 pr-md-0">
                                        <div className="ThresholdDiv">
                                            <p>Snapshot</p>
                                            <div >
                                                <p>Taken at block</p>
                                                <p className='qutoa'>15080360</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pioneerDao">
                                <div className="governancebox topBtnsDiv descriptiondd">
                                    <h3 className='mainheaddd'>Description</h3>

                                    {/* <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati.
                                    Et harum quidem rerum facilis est et expedita distinctio.
                                </p>
                                <p className='mt-4'>Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.</p> */}
                                    <div
                                        className="read-article-paragraph"
                                        dangerouslySetInnerHTML={{
                                            __html: proposal?.description,
                                        }}
                                    ></div>
                                </div>
                            </div>
                            {/* <div className='brdr'></div> */}
                            {/* {userInfo?.functionId === '32' ? '' : */}



                            {/* <div className="detail-table">
                                    <div className="heading">
                                        <h4>Proposed Transaction</h4>
                                    </div>
                                    <div>
                                        {ABiFun === 'transfer' ?
                                            <>
                                                <div className="text">
                                                    <h6>{userPropseTRx ? ` ${ABiFun}(${userPropseTRx[0]}, ${userPropseTRx[1]})` : ''}</h6>
                                                    <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionAmounts : userProposedTrx?.executionAmount}</p>
                                                </div>
                                                <div className="text">
                                                    <h6>{userPropseTRx ? `This Transaction Send   ${userPropseTRx[1] / 10 ** 18} (${userInfo?.targets}) from Treasury(${Environment.Treasury}) to ${userPropseTRx[0]}` : ''}</h6>
                                                    <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionTargets : userProposedTrx?.executionTarget}</p>
                                                </div>
                                            </>
                                            :
                                            ABiFun === 'fundDao' ?
                                                <>
                                                ${userInfo?.targets[0]}
                                                    <div className="text">
                                                        <h6>{userInfo ? `tomi.${ABiFun}()` : ''}</h6>
                                                        <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionAmounts : userProposedTrx?.executionAmount}</p>
                                                    </div>
                                                    <div className="text">
                                                        <h6>{userInfo ? `This Transaction send Tomi Token: "${userPropseTRx[2]}" Amount: "${userPropseTRx[3]}"  from Treasury(${Environment.Treasury}) to ${userPropseTRx[1]}` : ''}</h6>
                                                        <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionTargets : userProposedTrx?.executionTarget}</p>
                                                    </div>
                                                </>
                                                :
                                                ABiFun === 'safeTransferFrom' ?
                                                    <>

                                                        <div className="text">
                                                            <h6>{userPropseTRx ? ` ${ABiFun}(${userPropseTRx[0]}, ${userPropseTRx[1]}, ${userPropseTRx[2]})` : ''}</h6>
                                                            <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionAmounts : userProposedTrx?.executionAmount}</p>
                                                        </div>
                                                        <div className="text">
                                                            <h6>{userPropseTRx ? `This Transaction send  NFt(Token-Id): "${userPropseTRx[2]}" Amount: "${userPropseTRx[3]}"  from Treasury(${Environment.Treasury}) to ${userPropseTRx[1]}` : ''}</h6>
                                                            <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionTargets : userProposedTrx?.executionTarget}</p>
                                                        </div>
                                                    </>

                                                    : ABiFun === 'transferFrom' ?
                                                        <>

                                                            <div className="text">
                                                                <h6>{userPropseTRx ? ` ${ABiFun}(${userPropseTRx[0]}, ${userPropseTRx[1]}, ${userPropseTRx[2]})` : ''}</h6>
                                                                <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionAmounts : userProposedTrx?.executionAmount}</p>
                                                            </div>
                                                            <div className="text">
                                                                <h6>{userPropseTRx ? `This Transaction send  NFt(Token-Id): "${userPropseTRx[2]}"  from Treasury(${Environment.Treasury}) to ${userPropseTRx[1]}` : ''}</h6>
                                                                <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionTargets : userProposedTrx?.executionTarget}</p>
                                                            </div>
                                                        </>
                                                        : ABiFun === 'updateEmissions' ?
                                                            <>

                                                                <div className="text">
                                                                    <h6>{userPropseTRx ? ` ${ABiFun}(${userPropseTRx[0]}, ${userPropseTRx[1]}, ${userPropseTRx[2]}, ${userPropseTRx[3]}, ${userPropseTRx[4]}, ${userPropseTRx[5]})` : ''}</h6>
                                                                    <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionAmounts : userProposedTrx?.executionAmount}</p>
                                                                </div>
                                                                <div className="text">
                                                            <h6>{userPropseTRx ? `This Transaction send  NFt(Token-Id): "${userPropseTRx[2]}"  from Treasury(${Environment.Treasury}) to ${userPropseTRx[1]}` : ''}</h6>
                                                            <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionTargets : userProposedTrx?.executionTarget}</p>
                                                        </div>
                                                            </>
                                                            : userInfo && userInfo?.signatures[0] == " " ?
                                                                <>
                                                                    <div className="text">
                                                                        <h6>{userInfo ? ` TransferFrom(${userInfo.targets}, ${userInfo[6] / 10 ** 18})` : ''}</h6>
                                                                        <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionAmounts : userProposedTrx?.executionAmount}</p>
                                                                    </div>
                                                                </>
                                                                :
                                                                <div className="text">
                                                                    <h6>{userPropseTRx ? ` ${ABiFun}(${userPropseTRx[0]})` : ''}</h6>
                                                                    <p>{userInfo?.functionId === '19' ? userProposedTrx?.executionAmounts : userProposedTrx?.executionAmount}</p>
                                                                </div>
                                        }

                                    </div>

                                </div> */}



                            {/* <div className='brdr'></div> */}
                            <div className='comments'>
                                <div className='comments-heading'>
                                    <h2>Leave a comment</h2>
                                </div>
                                <div className='commentstextarea'>
                                    <p>Comment</p>
                                    <textarea className='commentstext' name="comment" value={inputs.comment} placeholder='Write comment' onChange={handleChange1} />
                                </div>
                                <div className='btnpost'>
                                    {mainDisable === true ? <button className='wait btnnnna' disabled>Post Comment</button> : <button onClick={addComment}>Post Comment</button>}
                                </div>
                            </div>
                            <div className='brdr'></div>
                            <div className='comment'>
                                <div className='headingsss'>
                                    <h2>Comments</h2>
                                </div>
                                {allCommentss?.map((elem, i) => {
                                    return (
                                        <>
                                            <div className='parent'>
                                                <div className='left-content'>
                                                    <img src={elem?.user ? elem?.user.picture : '\landing\avatar-03.png'} className='img-fluid' />
                                                </div>
                                                <div className='right-content'>
                                                    <div className='doyle'>
                                                        <h4 className='doylehead'>{elem?.user ? elem?.user.name : '0x0032....849de'}</h4>
                                                        <p>{(elem.createdAt).split('T')[0]}</p>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='mainpara-comment'>
                                                <div className='para'>
                                                    <p>{elem?.comment} </p>
                                                </div>
                                            </div>
                                            <div className='brdr'></div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                </div>
                <div className='vote'>
                    <div class="modal fade" id="exampleModalvote" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">

                                <div class="modal-body">
                                    <div className='main-heading'>
                                        <h3>Confirm Transacton</h3>
                                      
                                    </div>
                                    <div className='vote-btn'>
                                        <button className={vote === 1 ? 'votefor1' : 'votefor'} onClick={() => setVote(1)}>Vote in favour</button>
                                        <button className={vote === 0 ? 'voteagainst1' : 'voteagainst'} onClick={() => setVote(0)}>Vote Against</button>
                                        <button className={vote === 2 ? 'voteabstain1' : 'voteabstain'} onClick={() => setVote(2)}>Abstain</button>
                                    </div>
                                    <div className='confirmbtn'>
                                        <button className="" onClick={VoteFor}>Submit Vote</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Executed