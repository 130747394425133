import { useEffect, useState, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { getWeb3NoAccount } from "../utils/web3";
import axios from "axios";
import environment from '../utils/Environment';
import { getMintContract, getMainDaoContract, getTomiTokenProposalContract, getAllProposal ,getTomiPrices} from '../utils/contractHelpers'
import { getTomiContract } from '../utils/contractHelpers';
import useRefresh from './useRefresh'
import Environment from '../utils/Environment';
import web3NoAccount from '../utils/web3';

const useBalance = () => {
    const { account } = useWeb3React();
    const web3 = getWeb3NoAccount();
    const tokenAddress = environment.MintContract;
    const contract = getMintContract(tokenAddress, web3);
    const CheckUserNft = useCallback(async () => {
        try {
            if (account) {
                // console.log("in hooks")
                const approved = await contract.methods.balanceOf(account).call();
                // console.log("sasdf",approved)
                return approved;
            }
        } catch (error) {
            console.log("eeerrrrrr", error)
        }
    }, [contract, account]);
    return { CheckUserNft: CheckUserNft };
}


const useBalanceUSDT = () => {
    const web3 = getWeb3NoAccount();
    const tokenAddress = environment.TomiTokenDao;
    const contract = getTomiTokenProposalContract(tokenAddress, web3);
    const CheckBalanceUsdt = useCallback(async (id) => {
        try {
            const approved = await contract.methods.state(id).call();
            //  let convertedvalue =web3.utils.toWei(approved.toString(), "ether")
            // console.log("eeerrrrrr", approved)
            return (approved / 10 ** 18).toFixed(3);
        } catch (e) {
            console.log("eeerrrrrr", e)
        }

    }, [contract]);

    return { CheckBalanceUsdt: CheckBalanceUsdt };
}

const CheckAllowance = () => {
    const web3 = getWeb3NoAccount();
    const tokenAddress = environment.TomiTokenContract;
    // const marketAddress = environment.TomiTokenDao;
    const contract = getTomiContract(tokenAddress, web3);
    const CheckAllow = useCallback(async (account, marketAddress) => {
        // console.log("asdfasdfasdfasdfsad::::;",marketAddress,account)
        const approved = await contract.methods.allowance(account, marketAddress).call();
        return approved / 10 ** 18;
    }, [contract]);

    return { CheckAllow: CheckAllow };
};

const UserData = (id) => {
    const [balance, setBalance] = useState(0)
    const web3 = getWeb3NoAccount()
    const { slowRefresh } = useRefresh()
    useEffect(() => {
        const fetchBalance = async () => {
            const contract = getAllProposal(Environment.CreateProposal, web3);
            const approved = await contract.methods.proposals(id).call()
            // console.log("userinf0::::::::::",approved)
            setBalance((approved))
        }
        fetchBalance()
    }, [id, slowRefresh])
    return balance
};

const UserTreasure = () => {
    const [balance, setBalance] = useState(0)
    const tokenAddress = environment.TomiTokenContract;
    const { fastRefresh } = useRefresh()
    const contract1 = getTomiContract(tokenAddress, web3NoAccount);
    useEffect(() => {
        const myTreasure = async () => {
            const approved = await contract1.methods.balanceOf(environment.Treasury).call()
            let a= approved / 10 ** 18
          let b = await axios
            .get("https://pro-api.coingecko.com/api/v3/simple/price?ids=tominet&x_cg_pro_api_key=CG-AMT6oz8K7Qpx7qESamW9hKZu&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false&include_last_updated_at=false")
            let c = a * b.data.tominet.usd
            //  if(approved){
            //      const res = await web3.eth.getBalance(approved)
             console.log("reessss,res trea",b)
            setBalance(c)
            //  }

        }
        // const fetchBalance = async () => {
        //     const approved = await contract.methods.proposals(id).call()
        //     setBalance((approved))
        // }
        myTreasure()
    }, [fastRefresh])
    return balance
};

const UserTreasureEth = () => {
    const [balance, setBalance] = useState(0)
    const web3 = getWeb3NoAccount()
    const tokenAddress = environment.TomiTokenContract;
    const contract1 = getTomiContract(tokenAddress, web3);
    useEffect(() => {
        const myTreasure = async () => {
            const approved = await web3.eth.getBalance(environment.Treasury)
            //  if(approved){
            //      const res = await web3.eth.getBalance(approved)
            //  console.log("reessss,res trea",approved)
            setBalance(approved / 10 ** 18)
            //  }

        }
        // const fetchBalance = async () => {
        //     const approved = await contract.methods.proposals(id).call()
        //     setBalance((approved))
        // }
        myTreasure()
    }, [contract1])
    return balance
};

const UserTreasureUsd = () => {
    const [balance, setBalance] = useState(0)
    const web3 = getWeb3NoAccount()
    const tokenAddress = environment.UStTokenAddress;
    const contract1 = getTomiContract(tokenAddress, web3);
    useEffect(() => {
        const myTreasure = async () => {
            const approved = await contract1.methods.balanceOf(environment.Treasury).call()
            //  if(approved){
            //      const res = await web3.eth.getBalance(approved)
            //  console.log("reessss,res trea",approved)
            setBalance(approved / 10 ** 6)
            //  }

        }
        // const fetchBalance = async () => {
        //     const approved = await contract.methods.proposals(id).call()
        //     setBalance((approved))
        // }
        myTreasure()
    }, [contract1])
    return balance
};


//   Eth wallet BAlance 
const UserWalletBalance = () => {
    const { account } = useWeb3React();
    const web3 = getWeb3NoAccount();
    const fetchBalance = useCallback(async () => {
        try {
            if (account) {
                // console.log("in hooks")
                const res = await web3.eth.getBalance(account)
                // const res1 = new BigNumber(res)
                // console.log("sasdf in fetch balance",res/10**18)
                return res / 10 ** 18;
            }
        } catch (error) {
            console.log("eeerrrrrr", error)
        }
    }, [account, web3]);

    return { fetchBalance: fetchBalance };
}

const useBaseFeeGet = () => {
    const [balance, setBalance] = useState(0)
    const web3 = getWeb3NoAccount()
    // const tokenAddress = environment.TomiMainDao;
    const contract = getAllProposal(Environment.CreateProposal, web3);
    useEffect(() => {
        const CheckBaseFee = async () => {
            const approved = await contract.methods.proposalFee().call();
            // console.log("sasdf tomi tokem",(approved/10**26).toFixed(0))
            setBalance((approved / 10 ** 26).toFixed(0))

        }
        // const fetchBalance = async () => {
        //     const approved = await contract.methods.proposals(id).call()
        //     setBalance((approved))
        // }
        CheckBaseFee()
    }, [contract])
    return balance
};

const useMinProReq = () => {
    const [balance, setBalance] = useState(0)
    const web3 = getWeb3NoAccount()
    const contract = getAllProposal(Environment.CreateProposal, web3);
    useEffect(() => {
        const CheckBaseFee = async () => {
            const approved = await contract.methods.proposalMinAmountNFTs().call();
            // console.log("sasdf tomi tokem",(approved/10**26).toFixed(0))
            setBalance(approved)

        }
        // const fetchBalance = async () => {
        //     const approved = await contract.methods.proposals(id).call()
        //     setBalance((approved))
        // }
        CheckBaseFee()
    }, [contract])
    return balance
}

const useMinVOTEReq = () => {
    const [balance, setBalance] = useState(0)
    const web3 = getWeb3NoAccount()
    const contract = getAllProposal(Environment.CreateProposal, web3);
    useEffect(() => {
        const CheckBaseFee = async () => {
            const approved = await contract.methods.voteMinAmountNFTs().call();
            // console.log("sasdf tomi tokem",(approved/10**26).toFixed(0))
            setBalance(approved)

        }
        // const fetchBalance = async () => {
        //     const approved = await contract.methods.proposals(id).call()
        //     setBalance((approved))
        // }
        CheckBaseFee()
    }, [contract])
    return balance
}



// tomi Token Balance 
const useTomiBalance = () => {
    const { account } = useWeb3React();
    const web3 = getWeb3NoAccount();
    const tokenAddress = environment.TomiTokenContract;
    const contract = getTomiContract(tokenAddress, web3);
    const CheckUserBalance = useCallback(async () => {
        try {
            if (account) {
                // console.log("in hooks")
                const approved = await contract.methods.balanceOf(account).call();
                // console.log("sasdf tomi tokem",approved/10**18)
                return approved / 10 ** 18;
            }
        } catch (error) {
            console.log("eeerrrrrr", error)
        }
    }, [contract, account]);
    return { CheckUserBalance: CheckUserBalance };
}

// userNFt used 
const useNftUsed = () => {
    const { account } = useWeb3React();
    const web3 = getWeb3NoAccount();
    const contract = getAllProposal(Environment.CreateProposal, web3);
    // const contract = getMainDaoContract(tokenAddress, web3);
    const CheckNftUsed = useCallback(async (id) => {
        try {
            if (account) {
                // console.log("in hooks",id)
                const approved = await contract.methods.proposalNFTs(id).call();
                // console.log("sasdf tomi nft usedd",approved)
                return approved;
            }
        } catch (error) {
            console.log("eeerrrrrr", error)
        }
    }, [contract, account]);
    return { CheckNftUsed: CheckNftUsed };
}

const UserNftVoted = () => {
    const { account } = useWeb3React();
    const web3 = getWeb3NoAccount();
    // const tokenAddress = environment.TomiMainDao;
    // const contract = getMainDaoContract(tokenAddress, web3);
    const CheckNftVoted = useCallback(async ( Pid1,id) => {
        // console.log("in hooks",id,Pid1)
        try {
            if (account && id && Pid1) {
                const contract = getAllProposal(Environment.CreateProposal, web3);
                const approved = await contract.methods.voteNFTs(Pid1, id).call()
                // console.log('check waleed',approved)
                // console.log("sasdf tomi nft usedd",approved)
                return approved;
            }
        } catch (error) {
            console.log("eeerrrrrr", error)
        }
    }, [account]);
    return { CheckNftVoted: CheckNftVoted };
}

const TomiRealPrice = () => {
    const { account } = useWeb3React();
    const web3 = getWeb3NoAccount();
    const contract = getTomiPrices(Environment.TomiPrice, web3);
    // const contract = getMainDaoContract(tokenAddress, web3);
    const CheckTomiPrice = useCallback(async () => {
        // console.log("in hooks",id,Pid1)
        try {
            if (account) {
                const contract = getTomiPrices(Environment.TomiPrice, web3);
                const approved = await contract.methods.getTomiPrice().call()
                // console.log('check waleed',approved)
                // console.log("sasdf tomi nft usedd",approved)
                return approved / 10**8;
            }
        } catch (error) {
            console.log("eeerrrrrr", error)
        }
    }, [account,contract]);
    return { CheckTomiPrice: CheckTomiPrice };
}


// nftusedbolean
const UserVoteStatus = (id, nft) => {
    const [balance, setBalance] = useState(false)
    const { slowRefresh } = useRefresh()
    const web3 = getWeb3NoAccount()
    const contract = getAllProposal(Environment.CreateProposal, web3);
    useEffect(() => {
        const myvotee = async () => {
            console.log("herererrer vote", id, nft)
            const approved = await contract.methods.checkVotedNFTs(id, nft).call()

            console.log("herererrer vote1", approved)
            setBalance(approved)

        }
        // const fetchBalance = async () => {
        //     const approved = await contract.methods.proposals(id).call()
        //     setBalance((approved))
        // }
        myvotee()
    }, [id, slowRefresh, nft])

    return balance


};

const ProposalPostState = (id, tokenAddress) => {
    //   console.log("herererrer vote",tokenAddress)
    const [balance, setBalance] = useState(0)
    const web3 = getWeb3NoAccount()
    const contract = getAllProposal(Environment.CreateProposal, web3);
    useEffect(() => {
        const myvotee = async () => {
            const approved = await contract.methods.proposalExecutionState(id).call()
            //  console.log("reessss,res::::::::::::::::ss",approved)
            if (approved === '0') {
                setBalance('Pending')
            } else if (approved === '1') {
                setBalance('Active')
            } else if (approved === '2') {
                setBalance('Canceled')
            } else if (approved === '3') {
                setBalance('Defeated')
            } else if (approved === '4') {
                setBalance('Succeeded')
            } else if (approved === '5') {
                setBalance('Executed')
            } else if (approved === '6') {
                setBalance('Vetoed')
            }

        }
        // const fetchBalance = async () => {
        //     const approved = await contract.methods.proposals(id).call()
        //     setBalance((approved))
        // }
        myvotee()
    }, [id, tokenAddress])

    return balance


};

const GetConsensus = (id, tokenAddress) => {
    // console.log("herererrer")
    const [balance, setBalance] = useState(0)
    const web3 = getWeb3NoAccount()
    const { slowRefresh } = useRefresh()
    //   const tokenAddress = environment.TomiTokenDao;
    const contract = getTomiTokenProposalContract(tokenAddress, web3);
    useEffect(() => {
        const myvotee = async () => {
            const approved = await contract.methods.getConsensusVotes(id).call()
            //    console.log("reessss,res::::::::::::::::ss",approved)
            setBalance(approved)

        }
        // const fetchBalance = async () => {
        //     const approved = await contract.methods.proposals(id).call()
        //     setBalance((approved))
        // }
        myvotee()
    }, [tokenAddress, id, slowRefresh])
    return balance
}

const ProposalStatus = (id) => {
    //   console.log("in hooks status",id);
    const [balance, setBalance] = useState("Pending")
    const web3 = getWeb3NoAccount()
    const { slowRefresh } = useRefresh()
    // const tokenAddress = environment.TomiFundsDao;
    const contract = getAllProposal(Environment.CreateProposal, web3);
    useEffect(() => {
        //   console.log("in hooks useEffect",id);

        const myvotee = async () => {
            const approved = await contract.methods.state(id).call()
            //  console.log("reessss,res::::prooo:::::::",typeof approved)
            if (approved === '0') {
                setBalance('Pending')
            } else if (approved === '1') {
                setBalance('Active')
            } else if (approved === '2') {
                setBalance('Canceled')
            } else if (approved === '3') {
                setBalance('Defeated')
            } else if (approved === '4') {
                setBalance('Succeeded')
            } else if (approved === '5') {
                setBalance('Executed')
            } else if (approved === '6') {
                setBalance('Vetoed')
            }

        }
        // const fetchBalance = async () => {
        //     const approved = await contract.methods.proposals(id).call()
        //     setBalance((approved))
        // }
        myvotee()
        // console.log("in hooks:::::stat",balance);
    }, [id, slowRefresh])

    return balance


}


// const useUdtApprove = () => {
//     const web3 = getWeb3NoAccount();
//     const tokenAddress = environment.TomiTokenContract;
//     const contract = getTomiContract(tokenAddress, web3);
//     const CheckAvailableApprove = useCallback(async (account) => {
//         const approved = await contract.methods.balanceOf(account).call();
//         return approved;
//     }, [contract]);

//     return { CheckAvailableApprove: CheckAvailableApprove };
// }

export { useBalance, useBalanceUSDT,useMinProReq, useMinVOTEReq,UserTreasureEth,TomiRealPrice, UserVoteStatus, UserTreasureUsd, UserNftVoted, CheckAllowance, useNftUsed, UserData, useBaseFeeGet, ProposalPostState, GetConsensus, useTomiBalance, UserWalletBalance, UserTreasure, ProposalStatus };


